export function formatTime(duration: number): string {
  const m = Math.floor((duration % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(duration % 60)
      .toString()
      .padStart(2, "0")

  return `${m}:${s}`
}

export function formatDate(date: Date): string {
  const day = date.getDate().toString().padStart(2, "0"),
    month = (date.getMonth() + 1).toString().padStart(2, "0"),
    year = date.getFullYear().toString()

  return `${day}-${month}-${year}`
}
