export function stripLinksFromHtml(html: string) {
  return html.replace(new RegExp("<[^>]*>", "g"), "")
}

export function stringToSnake(string: string) {
  return string
    .match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
    )!
    .map((x) => x.toLowerCase())
    .join("_")
}

export function camelToSnake(string: string) {
  return string
    .replace(/[A-Z]|\d+/g, (match) => {
      return `_${match.toLowerCase()}`
    })
    .replace(/^_/, "")
}

export function kebabToSnake(string: string) {
  return stringToSnake(string).replace("-", "_").toLowerCase()
}

export function kebabToCamel(string: string) {
  return string
    .replace(/-([a-z])/g, (g) => g[1].toUpperCase())
    .replace(/^-/, "")
}

export function camelToKebab(string: string) {
  return string
    .replace(/[A-Z]|\d+/g, (match) => {
      return `-${match.toLowerCase()}`
    })
    .replace(/^-/, "")
}

export function snakeToCamel(string: string) {
  return string.replace(/_\w/g, (match) => {
    return match.substring(1).toUpperCase()
  })
}

export function uuid() {
  let dt = new Date().getTime()
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function initials(text: string, length?: number): string {
  if (!text) return ""
  text = text.replace(/\b(Mrs|Mr|Ms|Dr|Jr|Sr)\b\.?/g, " ").trim()

  return text
    .split(" ")
    .filter((word) => !["the", "third"].includes(word.toLowerCase()))
    .map((word) => word.charAt(0).toUpperCase())
    .join("")
    .substring(0, length ?? 2)
}
