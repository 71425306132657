import { setUser } from "@sentry/vue"
export type Session = {
  features?: string[]
  watched_videos?: string[]
  mandatory_training?: boolean
  user?: HubUser
}
export const useAuth = () => {
  const config = useRuntimeConfig()

  const session = useState<Session | undefined>("user", () => undefined)
  const token = useCookie("token", {
    path: "/",
    sameSite: "strict",
    secure: config.public.apiBase.startsWith("https://"),
    maxAge: 60 * 60 * 24 * 365,
  })
  const authenticated = computed(() => !!token.value)
  const score = computed(() => {
    if (session.value?.user) {
      // API returns scores that sum to 100 with different weights
      // Simulations are important, so they get get a high weight and their max value is 40
      return {
        quizzes: (session.value.user.score.quizzes / 20) * 100,
        simulations: (session.value.user.score.simulations / 40) * 100,
        skills: (session.value.user.score.skills / 10) * 100,
        videos: (session.value.user.score.videos / 30) * 100,
        total: session.value.user.score.total,
      }
    }
  })

  const logout = async () => {
    await navigateTo("/logout")
  }
  const hasFeature = (feature: string): boolean => {
    if (!session.value || !session.value.features) {
      return false
    }
    return session.value.features.includes(feature)
  }
  const fetchUser = async () => {
    if (!token.value) {
      return
    }
    const _session = await $fetch<Session>(`${config.public.apiBase}/session`, {
      headers: {
        authorization: `Bearer ${token.value}`,
      },
    })
    session.value = _session
    if (_session?.user) {
      const sentryUser = {
        id: _session.user.id ?? undefined,
      }
      setUser(sentryUser)
    }

    return _session
  }

  return {
    session,
    score,
    fetchUser,
    token,
    authenticated,
    logout,
    hasFeature,
  }
}
