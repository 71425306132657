import type { IFetchError } from "ofetch"
function isFetchError(
  obj: unknown,
): obj is { data: IFetchError<{ message: string }> } {
  return (
    (obj as { data?: IFetchError<{ message: string }> })?.data != undefined &&
    (obj as { data?: IFetchError<{ message: string }> })?.data?.message !=
      undefined
  )
}
type ValidationError = {
  errors: Record<string, string[]>
  message: string
}
function isValidationError(obj: unknown): obj is ValidationError {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "message" in obj &&
    typeof (obj as { message: unknown }).message === "string" &&
    "errors" in obj &&
    typeof (obj as { errors: unknown }).errors === "object" &&
    obj.errors !== null &&
    Object.values((obj as { errors: Record<string, unknown> }).errors).every(
      (value) =>
        Array.isArray(value) && value.every((item) => typeof item === "string"),
    )
  )
}
export function useLocalisedError(
  _error: unknown,
): ComputedRef<string | string[] | undefined> {
  const { t, te } = useI18n()

  return computed<string | string[] | undefined>(() => {
    const error = unref(_error)
    if ((error ?? undefined) == undefined) {
      return undefined
    }
    if (isFetchError(error)) {
      if (isValidationError(error.data)) {
        return Object.entries(error.data.errors).flatMap(([key, messages]) =>
          messages.map((message) => {
            // The message is either a translation key, in which case we can look it up
            // In other cases it's an English error message like "The last name field is required"
            return te(`dynamic_keys.api.validations.messages.${message}`)
              ? t(`dynamic_keys.api.validations.messages.${message}`, {
                  _field_: key,
                })
              : message
          }),
        )
      }

      if (error.data.status == 419) {
        return t("dynamic_keys.api.errors.csrf")
      }
      return te(`dynamic_keys.${error.data.message}`)
        ? t(`dynamic_keys.${error.data.message}`)
        : t("dynamic_keys.api.errors.unknown")
    }

    return t("dynamic_keys.api.errors.unknown")
  })
}
