<script setup lang="ts">
withDefaults(
  defineProps<{
    visible?: boolean
    allowDismiss?: boolean
  }>(),
  {
    visible: true,
    allowDismiss: false,
  },
)

const emit = defineEmits(["dismiss"])
</script>

<template>
  <Transition
    enter-active-class="transition-all duration-250 ease-out"
    leave-active-class="transition-all duration-250 ease-out"
    enter-from-class="opacity-0 scale-95"
    enter-to-class="opacity-100 scale-100"
    leave-from-class="opacity-100 scale-100"
    leave-to-class="opacity-0 scale-95"
    appear
  >
    <div
      v-if="visible"
      class="mb-6 flex w-full flex-1 rounded-lg p-4"
      role="alert"
    >
      <div class="flex-shrink-0">
        <svg
          class="size-5 text-red-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="ml-3">
        <button v-if="allowDismiss" class="btn btn-sm" @click="emit('dismiss')">
          x
        </button>
        <div v-if="$slots.header">
          <slot name="header" />
        </div>
        <slot />
      </div>
    </div>
  </Transition>
</template>
