import type { NuxtApp, UseFetchOptions } from "nuxt/app"

const getCachedData = (key: string, nuxtApp: NuxtApp) => {
  const data = nuxtApp.payload.data[key] ?? nuxtApp.static.data[key]
  const { data: fetchedAt } = useNuxtData(`${key}-fetchedAt`)
  if (!data || !fetchedAt) {
    fetchedAt.value = new Date()
    return
  }
  const expireMinutes = 30
  const expireTime = expireMinutes * 60 * 1000
  const expirationDate = new Date(fetchedAt.value)
  expirationDate.setTime(expirationDate.getTime() + expireTime)
  const isExpired = expirationDate.getTime() < Date.now()
  if (isExpired) {
    fetchedAt.value = new Date()
    return
  }
  return data
}

export function useCachedFetch<T>(
  url: string | (() => string),
  options?: Omit<UseFetchOptions<T>, "default"> & {
    default?: () => T | Ref<T | undefined>
  },
) {
  const optionsWithCache = options ?? {}
  const localisedCacheKeys = useLocalisedCacheKeys()

  // This is how nuxt does it as well
  const isPostRequest =
    toValue(
      optionsWithCache?.method as MaybeRef<string | undefined> | undefined,
    )?.toLowerCase() == "post"

  optionsWithCache.key = optionsWithCache.key ?? unref(url).toString()
  // Automatically set the locale if it's post request
  if (isPostRequest) {
    if (
      typeof optionsWithCache.body == "object" &&
      optionsWithCache.body != undefined &&
      !("locale" in optionsWithCache.body)
    ) {
      optionsWithCache.body = {
        ...optionsWithCache.body,
        locale: useI18n().locale,
      }
      localisedCacheKeys.value = Array.from(
        new Set([...localisedCacheKeys.value, optionsWithCache.key]),
      )
    } else if (optionsWithCache?.body == undefined) {
      optionsWithCache.body = { locale: useI18n().locale }
      localisedCacheKeys.value = Array.from(
        new Set([...localisedCacheKeys.value, optionsWithCache.key]),
      )
    }
  }
  optionsWithCache.getCachedData = getCachedData
  return useFetch(url, {
    ...optionsWithCache,
    $fetch: useNuxtApp().$api,
  })
}
