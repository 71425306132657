export default defineNuxtPlugin((nuxtApp) => {
  // const { session } = useAuth()
  nuxtApp.hook("vue:error", (error) => {
    console.error(error)
    // useBugsnag().notify(error as Error, (event) => {
    //   event.setUser(
    //     session.value?.user?.id ?? undefined,
    //     session.value?.user?.email ?? undefined,
    //     session.value?.user?.full_name ?? undefined,
    //   )
    // })
  })
})
