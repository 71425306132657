<template>
  <IconBase>
    <g>
      <circle cx="10" cy="10" r="10" stroke-width="0" />
      <path
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="m6.75 10.813 2.438 2.437c1.218-4.469 4.062-6.5 4.062-6.5"
      />
    </g>
  </IconBase>
</template>
