<script setup lang="ts">
import * as amplitude from "@amplitude/analytics-browser"

const config = useRuntimeConfig()
const scriptTag = computed(() => {
  if (import.meta.dev) {
    return []
  }
  return [
    {
      src: "https://cdn.usefathom.com/script.js",
      "data-site": config.public.fathomKey,
      "data-spa": "auto",
      defer: true,
    },
  ]
})
const linkTags = [
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/apple-touch-icon.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon-32x32.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon-16x16.png",
  },
]

useHead({
  bodyAttrs: {
    class: "relative min-h-svh w-full overflow-x-hidden bg-zinc-300/30",
  },
  link:
    process.env.NODE_ENV === "development"
      ? [
          { rel: "icon", type: "image/ico", href: "/dev/favicon.ico" },
          ...linkTags,
        ]
      : [{ rel: "icon", type: "image/ico", href: "/favicon.ico" }, ...linkTags],
  script: scriptTag,
  meta: [
    { name: "referrer", content: "no-referrer-when-downgrade" },
    { name: "robots", content: "noindex, nofollow" },
  ],
})

const route = useRoute()
const { setLocale } = useI18n()
if (route.query.locale) {
  setLocale(route.query.locale.toString())
}

const { session } = useAuth()
watch(session, () => {
  if (session.value?.user?.id) {
    const identifyObject = new amplitude.Identify()
    if (session.value.user.workspace_id) {
      identifyObject.set("workspace", session.value.user.workspace_id)
    }
    amplitude.identify(identifyObject, { user_id: session.value.user.id })
  } else {
    amplitude.reset()
  }
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
