// Taken from https://www.lichter.io/articles/nuxt3-sentry-recipe/
import { init, browserTracingIntegration, replayIntegration } from "@sentry/vue"

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (!sentry.dsn || import.meta.dev) {
    return
  }

  init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      browserTracingIntegration({ router }),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.2,

    replaysSessionSampleRate: import.meta.dev ? 1.0 : 0.1,
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  })
})
