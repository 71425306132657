<script setup lang="ts">
import type { ConcreteComponent } from "vue"

type AllowedLevels = 1 | 2 | 3 | 4
const props = defineProps<{
  level: AllowedLevels
  tag?: string | ConcreteComponent
}>()
const _tag = props.tag ?? `h${props.level}`
const classNames: Record<AllowedLevels, string> = {
  1: "text-2xl font-black tracking-tight text-slate-900 sm:text-3xl",
  2: "text-xl sm:text-2xl font-bold leading-8 text-slate-800",
  3: "text-lg font-bold leading-6 text-slate-800",
  4: "font-bold leading-6 text-slate-800",
}
const className = computed(() => {
  return classNames[props.level]
})
</script>
<template>
  <component :is="_tag" :class="className"><slot /></component>
</template>
